<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 분류 -->
          <c-select
            codeGroupCd='RAM_RISK_HAZARD_CLASS_CD'
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramRiskHazardClassCd"
            label="분류"
            v-model="searchParam.ramRiskHazardClassCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험요인 목록"
      tableId="riskHazrd01"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :selection="popupParam.type"
      rowKey="ramRiskHazardId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="선택" icon="add" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-hazard',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'single'
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        ramRiskHazardClassCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'ramRiskHazardClassName',
            field: 'ramRiskHazardClassName',
            label: '유해위험요인 분류',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'relevantLegalStandards',
            field: 'relevantLegalStandards',
            label: '관련근거(법적기준)',
            style: 'width:300px',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
    };
  },
  watch: {
    contentHeight() {
      this.grid.height = (this.contentHeight - 100) + 'px'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskHazard.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '유해위험요인을 지정하세요.', // 유해위험요인을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
